import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useUserStore = defineStore("user", () => {
  //State
  const apiUser = ref(null);
  const settings = ref({
    visual: {
      showQueue: false,
    }
  });

  //Getters
  const isLoggedIn = computed(
    () => apiUser.value != null
  );
  const cryptoKey = computed(() => {
    return apiUser.value?.key ?? import.meta.env.VITE_CRIPTO_KEY;
  });
  const tokens = computed(() => {
    return {
      token: apiUser.value?.token ?? null
    };
  });

  function setApiUser(user) {
    apiUser.value = user;
  }

  return {
    //State
    apiUser,
    settings,
    //Getters
    isLoggedIn,
    cryptoKey,
    tokens,
    //Actions
    setApiUser
  };
});
