//MODULO DASHBOARD
const DashboardTemplate = () => import("@/views/templates/DashboardTemplate.vue");
const SubroutesTemplate = () => import("@/views/templates/SubroutesTemplate.vue");
const ArtistDetail = () => import("@/views/dash/artists/ArtistDetail.vue");
const ArtistsList = () => import("@/views/dash/artists/ArtistsList.vue");
const HomeView = () => import("@/views/dash/home/HomeView.vue");
const GenreDetail = () => import("@/views/dash/genres/GenreDetail.vue");
const GenresList = () => import("@/views/dash/genres/GenresList.vue");
const SongsList = () => import("@/views/dash/songs/SongsList.vue");
const SongDetail = () => import("@/views/dash/songs/SongDetail.vue");
const QueueList = () => import("@/views/dash/queue/QueueList.vue");
const SeekerView = () => import("@/views/dash/seeker/SeekerView.vue");

const dashRoutes = {
  path: "/dash/:token?",
  name: "dashboard",
  redirect: { name: "home" },
  meta: {
    requiresAuth: true,
  },
  component: DashboardTemplate,
  children: [
    {
      path: "home",
      name: "home",
      component: HomeView,
    },
    {
      path: "queue",
      name: "queue",
      redirect: { name: "queueList" },
      component: SubroutesTemplate,
      children: [
        {
          path: "list",
          name: "queueList",
          component: QueueList,
          meta: {
            breadCrumb: [
              {
                label: "home.single",
                title: "home.goTo",
                to: { name: "home" },
              },
              {
                label: "queue.single",
              },
            ],
          },
        },
      ],
    },
    {
      path: "seeker",
      name: "seeker",
      component: SeekerView,
      meta: {
        breadCrumb: [
          {
            label: "home.single",
            title: "home.goTo",
            to: { name: "home" },
          },
          {
            label: "seeker.label",
          },
        ],
      },
    },
    {
      path: "songs",
      name: "songs",
      redirect: { name: "songsList" },
      component: SubroutesTemplate,
      children: [
        {
          path: "list/:q?",
          name: "songsList",
          component: SongsList,
          meta: {
            breadCrumb: [
              {
                label: "home.single",
                title: "home.goTo",
                to: { name: "home" },
              },
              {
                label: "song.plural",
              },
            ],
          },
        },
        {
          path: "detail/:id",
          name: "songDetail",
          component: SongDetail,
          meta: {
            breadCrumb: [
              {
                label: "home.single",
                title: "home.goTo",
                to: { name: "home" },
              },
              {
                label: "song.plural",
                title: "song.goToList",
                to: { name: "songsList" },
              },
              {
                label: "eki-item-detail",
              },
            ],
          },
        },
      ],
    },
    {
      path: "genres",
      name: "genres",
      redirect: { name: "genresList" },
      component: SubroutesTemplate,
      children: [
        {
          path: "list",
          name: "genresList",
          component: GenresList,
          meta: {
            breadCrumb: [
              {
                label: "home.single",
                title: "home.goTo",
                to: { name: "home" },
              },
              {
                label: "genre.plural",
              },
            ],
          },
        },
        {
          path: "detail/:id",
          name: "genreDetail",
          component: GenreDetail,
          meta: {
            breadCrumb: [
              {
                label: "home.single",
                title: "home.goTo",
                to: { name: "home" },
              },
              {
                label: "genre.plural",
                title: "genre.goToList",
                to: { name: "genresList" },
              },
              {
                label: "eki-item-detail",
              },
            ],
          },
        },
      ],
    },
    {
      path: "artists",
      name: "artists",
      redirect: { name: "artistsList" },
      component: SubroutesTemplate,
      children: [
        {
          path: "list",
          name: "artistsList",
          component: ArtistsList,
          meta: {
            breadCrumb: [
              {
                label: "home.single",
                title: "home.goTo",
                to: { name: "home" },
              },
              {
                label: "artist.plural",
              },
            ],
          },
        },
        {
          path: "detail/:id",
          name: "artistDetail",
          component: ArtistDetail,
          meta: {
            breadCrumb: [
              {
                label: "home.single",
                title: "home.goTo",
                to: { name: "home" },
              },
              {
                label: "artist.plural",
                title: "artist.goToList",
                to: { name: "artistsList" },
              },
              {
                label: "eki-item-detail",
              },
            ],
          },
        },
      ],
    },
  ],
};

export default dashRoutes;
