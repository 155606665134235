// Routes
import authRoutes from "./auth";
import dashRoutes from "./dash";
const NotFoundTemplate = () => import("@/views/templates/NotFoundTemplate.vue");

const routes = [
  {
    path: "/",
    redirect: { name: "dashboard" },
  },
  authRoutes,
  dashRoutes,
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundTemplate,
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
