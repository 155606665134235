import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { useUserStore } from "@/stores/user";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const userX = useUserStore();
  const isLoggedIn = userX.isLoggedIn;

  //? if route is for users logged in only
  if (requiresAuth) {
    //? there is no user logged in
    if (!isLoggedIn) {
      //? return login with redirection
      next({
        name: "noControl",
      });
      return;
    }
  } else {
    //? if route is public and there is a user logged in
    if (isLoggedIn) {
      //? redirect to home view
      next({ name: "dashboard" });
      return;
    }
  }
  next();
});

export default router;
