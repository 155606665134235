/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faAward,
  faBackwardStep,
  faBars,
  faBellConcierge,
  faBug,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faCirclePause,
  faCirclePlay,
  faCircleQuestion,
  faCircleXmark,
  faClockRotateLeft,
  faClose,
  faCompactDisc,
  faDemocrat,
  faDrum,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFaceFrown,
  faFaceGrimace,
  faFaceLaughBeam,
  faFaceLaughSquint,
  faFaceSmileWink,
  faForward,
  faForwardStep,
  faGamepad,
  faGear,
  faHandPeace,
  faHandsBubbles,
  faHandsClapping,
  faHashtag,
  faHome,
  faHouseLaptop,
  faIdCard,
  faLanguage,
  faLayerGroup,
  faList,
  faListOl,
  faMaximize,
  faMicrophone,
  faMinus,
  faMusic,
  faPeopleLine,
  faPlay,
  faSearch,
  faSkullCrossbones,
  faSort,
  faSpinner,
  faStopwatch,
  faThumbsDown,
  faThumbsUp,
  faTriangleExclamation,
  faUser,
  faVolumeHigh,
  faVolumeLow,
  faVolumeXmark
} from "@fortawesome/free-solid-svg-icons";

import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";

import { faFacebook, faGoogle, faInstagram, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(
  faCalendar,
  faLinkedin,
  faArrowRightToBracket,
  faCircleInfo,
  faTriangleExclamation,
  faCircleXmark,
  faCircleCheck,
  faMusic,
  faCirclePlay,
  faCirclePause,
  faDrum,
  faListOl,
  faMaximize,
  faVolumeLow,
  faVolumeXmark,
  faVolumeHigh,
  faBackwardStep,
  faForwardStep,
  faSearch,
  faMicrophone,
  faLayerGroup,
  faClockRotateLeft,
  faList,
  faHome,
  faEye,
  faEyeSlash,
  faGoogle,
  faUser,
  faCircleQuestion,
  faBug,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faYoutube,
  faPlay,
  faEllipsisVertical,
  faClock,
  faHashtag,
  faSort,
  faCaretDown,
  faCaretUp,
  faGear,
  faGamepad,
  faIdCard,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faHouseLaptop,
  faCheck,
  faMinus,
  faAdd,
  faChevronUp,
  faChevronDown,
  faHandsClapping,
  faPeopleLine,
  faHandsBubbles,
  faSkullCrossbones,
  faDemocrat,
  faFaceLaughBeam,
  faFaceLaughSquint,
  faFaceFrown,
  faFaceGrimace,
  faThumbsUp,
  faAward,
  faStopwatch,
  faBellConcierge,
  faThumbsDown,
  faFaceSmileWink,
  faHandPeace,
  faInstagram,
  faFacebook,
  faBars,
  faForward,
  faClose,
  faSpinner,
  faArrowLeft,
  faArrowRight,
  faLanguage,
  faCompactDisc
);

export default FontAwesomeIcon;
