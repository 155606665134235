<template>
  <EkiNotifs></EkiNotifs>
  <RouterView />
</template>

<script setup>
import { RouterView } from "vue-router";
import EkiNotifs from "./components/eki-notifs/EkiNotifs.vue";
import { registerSW } from 'virtual:pwa-register'

registerSW({ immediate: true })
</script>

<style scoped></style>
