//MODULO AUTH
const SubroutesTemplate = () => import("@/views/templates/SubroutesTemplate.vue");
const LoginView = () => import("@/views/auth/LoginView.vue");
const NoControlView = () => import("@/views/auth/NoControlView.vue");

const authRoutes = {
  path: "/auth",
  name: "auth",
  redirect: { name: "noControl" },
  component: SubroutesTemplate,
  children: [
    {
      path: "login/:token",
      name: "login",
      component: LoginView
    },
    {
      path: "no-control",
      name: "noControl",
      component: NoControlView
    }
  ],
};

export default authRoutes;
